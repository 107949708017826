export const App = {
  methods: {
    build_theme() {
      let el = document.querySelector("style[name=setting]");
      let theme_settings = this.$storage.get("theme_settings");
      if (theme_settings) {
        el.innerHTML = theme_settings;
      }
      this.$axios.get("home/settings/theme").then(({ data }) => {
        let r = "*{";
        for (let k in data) {
          if (this.$helper.hasKey(data, k)) {
            r += `${k}:${data[k]};`;
          }
        }
        r += "}";
        el.innerHTML = r;
        this.$storage.set("theme_settings", r);
      });
    },
    status(n) {
      n = parseInt(n);
      if (n === 1) {
        return this.$t("not_shipped");
      } else if (n === 2) {
        return this.$t("in_progress");
      } else if (n === 3) {
        return this.$t("posted");
      } else if (n === 4) {
        return this.$t("returned");
      } else if (n === 5) {
        return this.$t("send_depot");
      } else if (n === -200) {
        return this.$t("unpaid");
      } else {
        return n;
      }
    },
    userInfo() {
      this.build_theme();
      this.$r.store.user = { login: false, info: {} };
      this.$r.store.user_loaded = false;
      setTimeout(() => {
        this.$translate.loads(["renusify"]);
        this.$translate.load();
      }, 100);
      if (
        this.$storage.has("auth.token") &&
        this.$storage.get("auth.token") !== "undefined"
      ) {
        this.$axios.get("user").then(
          ({ data }) => {
            this.$r.store.user = data;
            this.$r.store.user_loaded = true;
          },
          () => {
            this.$r.store.user_loaded = true;
          }
        );
      } else {
        this.$r.store.user_loaded = true;
      }
      if (this.$storage.get("theme.night") !== null) {
        this.$r.dark = this.$storage.get("theme.night");
      }
      if (this.$storage.get("rtl") !== null) {
        this.$r.rtl = this.$storage.get("rtl");
      }
      if (this.$storage.get("lang") !== null) {
        this.$r.lang = this.$storage.get("lang");
      }
    },
  },
  computed: {
    all_lang_loaded() {
      for (let item in this.$r.store.langs_loaded) {
        if (this.$r.store.langs_loaded[item] === false) {
          return false;
        }
      }
      return true;
    },
  },
};
